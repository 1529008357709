import { useEffect } from 'react'
import { notNullish } from '../helpers/guard'

type UseUpdateDatalayerLoyaltyBalanceArgs = {
  loyaltyBalance: number | null,
  isLoyaltyMember: boolean,
}

export const useUpdateDataLayerLoyaltyBalance = ({
  loyaltyBalance,
  isLoyaltyMember,
}: UseUpdateDatalayerLoyaltyBalanceArgs) => {
  useEffect(() => {
    if (notNullish(loyaltyBalance)) {
      window.dataLayer?.push({
        event: 'LoyaltyBalance',
        isLoyaltyMember,
        loyaltyBalance,
      })
    }
  }, [loyaltyBalance, isLoyaltyMember])
}
