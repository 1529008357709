import { useEffect, useState } from 'react'
import { error } from '@/services/Log'
import { checkIfClientside } from '@/helpers/checkRuntime'
import { useUpdateDataLayerLoyaltyBalance } from '@/hooks/useUpdateDataLayer'
import allPromisesWithRetries from '@/helpers/allPromisesWithRetries'

const initializeCustomer = async (setStoreCredits: (value: number) => void) => {
  try {
    const [{ getCustomer }] = await allPromisesWithRetries(() => [
      import('@/helpers/graphql'),
    ])
    const { customer } = await getCustomer()
    const credit = customer?.storeCredit?.[0]?.value || 0
    setStoreCredits(credit)
  } catch (err) {
    error('Failed to retrieve store credit', err)
  }
}

const updateBalance = (setBalance: (balance: number) => void) => (value: number) => setBalance(
  parseFloat(`${value || ''}`),
)
const refreshBalance = (setBalance: (balance: number) => void) => {
  let attemptToLoadLoyalty = 0
  let unsubscribeLoginChanges: (() => void) | undefined
  const intervalId = setInterval(() => {
    if (attemptToLoadLoyalty > 5) {
      clearInterval(intervalId)
      return
    }
    attemptToLoadLoyalty += 1
    unsubscribeLoginChanges = window.shoptoken?.subscribeToLoginChanges(() => {
      window.shoptoken?.subscribeToBalanceRefresh?.(updateBalance(setBalance))
    })
  }, 1000)
  return () => {
    try {
      clearInterval(intervalId)
      window.shoptoken?.unsubscribeToBalanceRefesh()
      unsubscribeLoginChanges?.()
    } catch (err) {
      error('Failed to unsubscribe from balance refresh', err)
    }
  }
}

const conciliateBalance = (balance: null | number, storeCredit: null | number) => {
  if (balance !== null && storeCredit !== null) {
    return balance + storeCredit
  }
  return null
}

const useZGoldBalance = () => {
  const [balance, setBalance] = useState<number | null>(null)
  const [storeCredit, setStoreCredits] = useState<number | null>(null)
  useUpdateDataLayerLoyaltyBalance({
    loyaltyBalance: (balance ?? 0) + (storeCredit ?? 0),
    isLoyaltyMember: (
      checkIfClientside() && window?.shoptoken?.isCustomerInLoyaltyProgram()
    ) ?? false,
  })
  useEffect(() => {
    initializeCustomer(setStoreCredits)
  }, [])
  useEffect(() => (
    refreshBalance(setBalance)
  ), [])

  return conciliateBalance(balance, storeCredit)
}

export default useZGoldBalance
