/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { formatMoney } from '@/helpers/formatMoney'
import styles from './ZGoldBalance.module.scss'
import { checkIfServerside } from '../../../../helpers/checkRuntime'
import { nullish } from '../../../../helpers/guard'

type ZGoldBalanceProps = {
  balance: number | null
}
const ZGoldBalance: React.FC<ZGoldBalanceProps> = ({
  balance,
}) => {
  const handleZGoldClick = () => window.shoptoken?.showWidget()
  if (nullish(balance)) {
    return null
  }
  if (checkIfServerside()) {
    return null
  }
  if (!(window.shoptoken?.isCustomerInLoyaltyProgram())) {
    return null
  }

  return (
    <li className="navUser-item navUser-item--zgold">
      <a className="navUser-action zgoldButton" href="#" onClick={handleZGoldClick}>
        <div className={styles.zgoldLogo}>
          <img src="__statics/images/loyalty.svg" alt="OKL Rewards" />
        </div>
        <span data-testid="zgold-balance">
          {formatMoney(balance)}
        </span>
      </a>
    </li>
  )
}

export default ZGoldBalance
