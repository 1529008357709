import { useEffect, useRef } from 'react'
import { ProductHelpers } from '@/helpers/Product'
import type { Product } from '@/types/SearchAPI/SAPIProduct'
import { error } from '@/services/Log'
import type { StandardCart } from '@/types/ShopFront/CheckoutStandards'
import allPromisesWithRetries from '@/helpers/allPromisesWithRetries'

import { useContentLoaded } from './useContentLoaded'

export const useListrakProductTracking = (product: Product, sku: string) => {
  const collectionVariants = ProductHelpers.getCollectionVariants(product)
  const contentLoaded = useContentLoaded()
  useEffect(() => {
    if (contentLoaded && !product.options.length && !collectionVariants.length) {
      allPromisesWithRetries(() => [import('@/services/Listrak/index')])
        .then(([{ default: Listrak }]) => {
          Listrak.listrakProductBrowse(sku)
        })
        .catch(error)
    }
  }, [contentLoaded, sku, product, collectionVariants])
}

function areCartItemsSame(cart1: StandardCart, cart2: StandardCart): boolean {
  if (cart1.lineItems.length !== cart2.lineItems.length) {
    return false
  }
  const itemToPropsString = (item: { sku: string, quantity: number }) => (
    JSON.stringify([item.sku, item.quantity])
  )
  const cart1SkuQuantity = new Set(cart1.lineItems.map(itemToPropsString))
  const cart2SkuQuantity = new Set(cart2.lineItems.map(itemToPropsString))
  return !([...cart1SkuQuantity.entries()]).find(([sku]) => !cart2SkuQuantity.has(sku))
}

export const useListrakCartTracking = (cart: StandardCart | null) => {
  const cartRef = useRef(cart)
  const contentLoaded = useContentLoaded()
  useEffect(() => {
    allPromisesWithRetries(() => [import('@/services/Listrak/index')])
      .then(([{ default: Listrak }]) => {
        try {
          if (cart && contentLoaded) {
            if (cartRef.current) {
              if (!areCartItemsSame(cart, cartRef.current)) {
                Listrak.submitCart(cart)
              }
            } else {
              Listrak.submitCart(cart)
            }
          } else if (cartRef.current) {
            Listrak.listrakEmptyCart()
          }
        } catch (err) {
          error('Failed to perform useListrakCartTracking', err)
        } finally {
          cartRef.current = cart
        }
      }).catch(error)
  }, [cart, contentLoaded])
}
