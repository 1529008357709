import React, { useEffect, useState } from 'react'

import { GraphQlCustomer } from '@/helpers/graphql'
import { IMeganav } from '@/types/Meganav'

import { useListrakCartTracking } from '@/hooks/useListrakProductTracking'
import { error } from '@/services/Log'
import type { StandardCart } from '@/types/ShopFront/CheckoutStandards'
import allPromisesWithRetries from '@/helpers/allPromisesWithRetries'

import dynamic from 'next/dynamic'
import { STORE_MAIN_LOGO_URL } from '@/data/constants/STORE_MAIN_LOGO_URL'
import { STORE_MINI_LOGO_URL } from '@/data/constants/STORE_MINI_LOGO_URL'
import { isFeatureEnabled } from '@/helpers/isFeatureEnabled'
import useZGoldBalance from '@/hooks/zgold'
import ZGoldBalance from '@/components/Sitewide/Navbar/ZGoldBalance'
import { apply } from '@/helpers/FP'

const MegaNav = dynamic(import('@/components/dls/meganav/MegaNav'))
const SearchBox = dynamic(import('@/components/Sitewide/Navbar/SearchBox'))
const CartPreview = dynamic(import('@/components/Sitewide/Navbar/CartPreview/CartPreview'))
const MyProfile = dynamic(import('@/components/Sitewide/Navbar/MyProfile'))
const UserLogin = dynamic(import('@/components/Sitewide/Navbar/UserLogin/UserLogin'))
interface NavbarProps {
  user: GraphQlCustomer | null,
  cart: StandardCart | null,
  pageType: string,
  megaNav: IMeganav,
  onUpdateCart: React.Dispatch<React.SetStateAction<StandardCart | null>>,
  isEventBannerVisible: boolean,
}
const Navbar: React.FC<NavbarProps> = ({
  cart,
  onUpdateCart,
  pageType,
  megaNav,
  user,
  isEventBannerVisible,
}) => {
  useListrakCartTracking(cart)
  const [forceMobileExpandedState, setForceMobileExpandedState] = useState(false)
  const [searchBarQuery, setSearchBarQuery] = useState('')

  useEffect(() => {
    allPromisesWithRetries(() => [import('@/services/ExtendWarranty/index')])
      .then(([{ default: ExtendWarranty }]) => ExtendWarranty.initXMiniCart())
      .catch(error)
  }, [])

  useEffect(() => {
    setForceMobileExpandedState(!!searchBarQuery.length)
  }, [searchBarQuery])

  // Some old Iphones scroll the screen messing the UX when focusing on the field
  // As a solution we force the UX for 500ms after focusing on it
  const onFocus = () => apply(setForceMobileExpandedState)(true)
  const onBlur = () => apply(setForceMobileExpandedState)(!!searchBarQuery.length)
  const isLoyaltyEnabled = isFeatureEnabled('isLoyaltyProgramEnabled')
  const zGoldBalance = useZGoldBalance()
  return (
    <MegaNav
      isEventBannerVisible={isEventBannerVisible}
      desktopBreakpoint="lg"
      logo={(
        <div
          className="mx-auto mt-2 mb-5 lg:mt-0 lg:mb-0"
          style={{
            maxWidth: '200px',
            minWidth: '200px',
            width: '200px',
            minHeight: '64px',
            height: '64px',
            maxHeight: '64px',
            backgroundImage: `url(${STORE_MAIN_LOGO_URL})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          }}
        />
      )}
      smallLogo={STORE_MINI_LOGO_URL}
      megaNavItems={megaNav.meganavItems}
      headerLinks={megaNav.headerLinks}
      mobileNavIcons={(
        [
          <ul className="flex justify-center align-middle items-center" key="mobileNavIcons">
            <MyProfile
              user={user}
              loyalty={
                isLoyaltyEnabled ? <ZGoldBalance balance={zGoldBalance} /> : null
              }
            />
            <CartPreview
              color="black"
              cart={cart}
              onUpdateCart={onUpdateCart}
              pageType={pageType}
            />
          </ul>,
        ]
      )}
      searchBar={(
        <div className="lg:flex flex-row justify-center space-x-1 items-center align-middle w-full">
          <SearchBox
            query={searchBarQuery}
            setQuery={setSearchBarQuery}
            id="MeganavSearchBox"
            onFocus={onFocus}
            onBlur={onBlur}
          />
          <div
            className="hidden lg:block pl-4"
          >
            <UserLogin user={user} zGoldBalance={zGoldBalance} />
          </div>
          <div
            className="hidden lg:block relative pl-4"
          >
            <CartPreview
              color="black"
              cart={cart}
              iconSize="md"
              onUpdateCart={onUpdateCart}
              pageType={pageType}
            />
          </div>
        </div>
      )}
      mobileUserRedirectUrl={user ? '/account' : '/login'}
      mobileUserIconText={user ? `Hi, ${user.firstName}` : 'Log in'}
      forceMobileExpandedState={forceMobileExpandedState}
    />
  )
}

export default Navbar
