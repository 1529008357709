// formatMoney takes a numeric value and returns a printable string
// formatMoney(123456.78) => $123,456.78
const BLANK_MONEY_VALUE = '--'
export const formatMoney = (value) => (typeof value === 'number'
  ? new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  }).format(parseFloat(`${value || '0'}`))
  : BLANK_MONEY_VALUE)

export default formatMoney
