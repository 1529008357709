export class NullOrUndefinedError extends Error {
  constructor(variableName: string) {
    super(`${variableName} is null or undefined`)
    this.name = 'NullOrUndefinedError'
  }
}

/**
 * @Example
 * ```
 * const variable = null
 * try {
 *   guard(variable, 'variable')
 * } catch (error) {
 *   console.error(error)
 * }
 * ```
 */
export function guard <T>(variable: T, variableName: string):
  asserts variable is Exclude<T, undefined | null> {
  if (variable === null || variable === undefined) {
    throw new NullOrUndefinedError(variableName)
  }
}

/**
 * Example usage:
 *```
 * const array = [1, null, 3, undefined, 5]
 * const result = array.filter(notNullish)
 *```
 */
export const notNullish = <T>(
  value: T,
): value is Exclude<T, null | undefined> => value !== null && value !== undefined

/**
 * Example usage:
 * ```
 * const variable = null
 * if (nullish(variable)) {
 *   console.error('variable is null or undefined')
 * } else {
 *   log(variable)
 * }
 * ```
 */
export const nullish = <T>(
  value: T | null | undefined,
): value is null | undefined => value === null || value === undefined
